<template>
  <section>
    <vs-card class="grades">
      <div class="vs-modal">
        <!--Modal Header-->
        <div class="modal-header">
          <h4>{{ $t("assignment.review_teacher_grade") }}</h4>
        </div>

        <!--Modal Body-->
        <div v-if="exam">
          <div class="my-8">
            <div class="w-full flex justify-around flex-wrap mt-8">
              <div>
                <h5>
                  {{ $t("exam.exam_name") }}:
                  <span class="font-bold"> {{ examData.name }} </span>
                </h5>
              </div>
              <div>
                <h5>
                  {{ $t("basic.subject") }}:
                  <span class="font-bold">
                    {{ examData.subject.name }}
                  </span>
                </h5>
              </div>
              <div>
                <h5>
                  {{ $t("basic.by") }}:
                  <span class="font-bold"> {{ examData.teacher.name }} </span>
                </h5>
              </div>
            </div>
          </div>
          <div class="vx-row">
            <template
              v-if="
                !examAssessment.assessment_detailed ||
                !examAssessment.assessment
              "
            >
              <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
                <div class="vs-input--label">
                  {{ $t("exam.criteria") }}
                </div>
                <div class="content-box w-full my-1">
                  {{ $t("exam.no_criteria_assigned") }}
                </div>
              </div>
            </template>
            <template v-else-if="sectionName === 'MYP'">
              <div
                class="vx-col lg:w-1/4 md:w-1/2 w-full"
                v-for="item in examAssessment.assessment_detailed"
                :key="item.key"
              >
                <div class="vs-input--label">
                  {{ item.key + " - " + item.title }}
                </div>
                <div class="content-box w-full my-1">{{ item.value }}</div>
              </div>
            </template>
            <template v-else-if="sectionName === 'PYP'">
              <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
                <div class="vs-input--label">
                  {{ $t("exam.criteria") }}
                </div>
                <div class="content-box w-full my-1">
                  {{
                    examData.assessTask_Criteria[0].key +
                    " - " +
                    examData.assessTask_Criteria[0].title
                  }}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="vx-col w-full">
                <!-- <div class="content-box w-full my-1">{{ examAssessment.assessment[0].value }}</div> -->
              </div>
            </template>
            <template>
              <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
                <div class="vs-input--label">
                  {{ $t("exam.score_is") }}
                </div>
                <div class="content-box w-full my-1">
                  {{
                    examData.score +
                    " " +
                    $t("exam.out_of") +
                    " " +
                    examData.total_points
                  }}
                </div>
              </div>
            </template>
          </div>
          <div v-if="examData.is_show_answer">
            <div class="text-center my-12">
              <h3>{{ $t("exam.exam_qustions") }}</h3>
            </div>
            <vs-row class="mb-2" v-if="examData.type.type !== 'Paper'">
              <vs-col
                v-for="(question, idx) in examData.questions"
                :key="idx"
                vs-w="12"
              >
                <div class="flex justify-between flex-wrap items-baseline">
                  <h4 class="mr-2">
                    {{ question.title }} :
                    <span
                      class="font-bold inline-block"
                      v-html="question.description"
                    ></span>
                  </h4>
                  <div>
                    <!-- In points per question senrio -->
                    <vx-input-group
                      v-if="examData.grading_type.type == 'Points_Per_Question'"
                    >
                      <vs-input
                        disabled
                        v-model="question.score"
                        class="w-full"
                        :name="`question${idx + 1}`"
                      />
                      <template slot="append">
                        <div class="append-text bg-primary">
                          <span
                            >{{ $t("exam.out_of") }} {{ question.points }}</span
                          >
                        </div>
                      </template>
                    </vx-input-group>
                  </div>
                </div>
                <!-- Handle Answer Questions -->
                <div v-if="question.type.type === 'Essay'" class="w-full mt-4">
                  <vs-textarea v-model="question.answers[0]" disabled />
                </div>
                <vs-list
                  v-if="
                    question.has_answered_correctly &&
                    question.type.type !== 'Essay'
                  "
                >
                  <div v-for="(answer, ansx) in question.answers" :key="ansx">
                    <vs-list-item color="success">
                      <template #title>
                        <div class="flex items-baseline">
                          <!--
                          #TODO: Refactor the logic of it using computed property
                          -->
                          <vs-icon
                            icon="verified_user"
                            class="mr-2"
                            :color="
                              (answer.has_choosen && answer.is_correct) ||
                              (question.has_answered_correctly &&
                                question.type.type === 'True or False')
                                ? 'success'
                                : 'dark'
                            "
                          ></vs-icon>
                          <p
                            v-if="question.type.type === 'True or False'"
                            class="font-bold"
                            :class="
                              question.has_answered_correctly
                                ? 'text-success'
                                : ''
                            "
                          >
                            {{ answer.is_correct ? "True" : "False" }}
                          </p>
                          <p
                            v-else
                            v-html="answer.description"
                            class="font-bold"
                            :class="answer.is_correct ? 'text-success' : ''"
                          ></p>
                        </div>
                      </template>
                    </vs-list-item>
                  </div>
                </vs-list>
                <div
                  v-else-if="
                    !question.has_answered_correctly &&
                    question.type.type !== 'Essay'
                  "
                >
                  <vs-list>
                    <div v-for="(answer, ansx) in question.answers" :key="ansx">
                      <vs-list-item>
                        <template #title>
                          <div class="flex items-baseline">
                            <vs-icon
                              icon="close"
                              class="mr-2"
                              v-if="
                                (answer.has_choosen && !answer.is_correct) ||
                                (!question.has_answered_correctly &&
                                  question.type.type === 'True or False')
                              "
                              color="danger"
                            >
                            </vs-icon>
                            <vs-icon
                              icon="verified_user"
                              class="mr-2"
                              v-else
                              :color="
                                answer.has_choosen
                                  ? answer.is_correct
                                    ? 'success'
                                    : 'danger'
                                  : 'dark'
                              "
                            ></vs-icon>
                            <p
                              v-if="question.type.type === 'True or False'"
                              class="font-bold"
                              :class="
                                question.has_answered_correctly
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              {{ !answer.is_correct ? "True" : "False" }}
                            </p>
                            <p
                              v-else
                              v-html="answer.description"
                              class="font-bold"
                              :class="{
                                'text-danger':
                                  answer.has_choosen && !answer.is_correct,
                                'text-success':
                                  answer.is_correct && answer.has_choosen,
                              }"
                            ></p>
                          </div>
                        </template>
                      </vs-list-item>
                    </div>
                  </vs-list>
                  <div class="mt-2 flex">
                    <vs-card>
                      <div slot="header">
                        <h6>{{ $t("basic.correct_answer") }} :</h6>
                      </div>
                      <div>
                        <ul class="list-disc">
                          <li
                            v-for="(answer, ansx) in question.answers"
                            :key="ansx"
                            class="deco"
                          >
                            <div
                              v-if="
                                !question.has_answered_correctly &&
                                question.type.type == 'True or False'
                              "
                            >
                              <p class="font-bold">
                                {{ answer.is_correct ? "True" : " False" }}
                              </p>
                            </div>
                            <div
                              v-else-if="
                                answer.is_correct &&
                                question.type.type != 'True or False'
                              "
                            >
                              <p
                                class="font-bold"
                                v-html="answer.description"
                              ></p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </vs-card>
                  </div>
                </div>
                <hr
                  class="text-grey my-4"
                  v-if="examData.questions.length - 1 > idx"
                />
              </vs-col>
            </vs-row>
            <vs-row v-else>
              <vs-col vs-w="12" class="mb-4">
                <label class="vx-col w-full">{{
                  $t("examData.total_points")
                }}</label>
                <vx-input-group>
                  <vs-input
                    disabled
                    v-model="examData.score"
                    class="w-full"
                    :name="`exam points`"
                  />
                  <template slot="append">
                    <div class="append-text bg-primary">
                      <span
                        >{{ $t("examData.out_of") }} {{ examData.points }}</span
                      >
                    </div>
                  </template>
                </vx-input-group>
              </vs-col>
              <vs-col vs-w="12">
                <div
                  class="w-full mt-4"
                  v-if="
                    examData.extra_doc_link &&
                    examData.extra_doc_link != this.institutionAssetsLink
                  "
                >
                  <center>
                    <embed
                      :src="examData.attachments[0].attachment"
                      width="100%"
                      height="500"
                    />
                  </center>
                  <a
                    :href="examData.attachments[0].attachment"
                    download
                    class="mt-4"
                    style="display: block"
                    ref="file"
                  >
                    <vs-button class="mb-base mr-0 ml-auto">{{
                      $t("form.download_file")
                    }}</vs-button>
                  </a>
                </div>
              </vs-col>
            </vs-row>
          </div>
          <div class="vx-col w-full mt-2" v-if="examData.assessTask">
            <div class="w-full">
              <div class="vs-input--label">
                {{ $t("basic.teacher_note") }}
              </div>
              <div class="content-box teacher-note w-full">
                {{ examAssessment.student_message }}
              </div>
            </div>
          </div>
        </div>

        <!--Modal Footer-->
        <div class="modal-footer">
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-button
                @click="backToStudentProfile"
                class="mb-2 mx-5"
                color="warning"
                type="border"
                >{{ $t("config.BackToProfile") }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </vs-card>
  </section>
</template>

<script>
export default {
  name: 'exam-grade',
  props: {
    exam: {
      required: true
    }
  },
  data () {
    return {
      examData: {},
      examAssessment: {}
    }
  },
  created () {
    this.loadDate()
  },
  computed: {
    sectionName () {
      return this.examData.grade.section.name
    }
  },

  methods: {
    loadDate () {
      this.examAssessment = this.exam.assess
      this.examData = this.exam.exam
    },
    backToStudentProfile () {
      this.$router.push({
        name: 'BackToProfile',
        params: {
          id: this.authUser.uid
        }
      })
    }
  }
}
</script>

<style lang="scss">
.grades {
  .content-box {
    min-height: 38px;
    height: auto;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid rgba(var(--vs-primary), 0.7);
    font-weight: 500;
    font-size: 13px;
  }
  .teacher-note {
    min-height: 105px !important;
    max-height: 250px;
    height: 100%;
    overflow-y: scroll;
  }
}
</style>
